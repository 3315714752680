import React from "react";
import OAuthMessage from "../OAuthMessage/OAuthMessage";
import "./Popup.css";

export default function Popup() {
  return (
    <div className="wrapper">
      <div className="content">
        <div className="message-content">
          <div style={{ opacity: 1, transform: "none" }}>
            <OAuthMessage />
          </div>
        </div>
      </div>
    </div>
  );
}
