import React, { useState } from "react";
import logo from "../../img/logo.png";

import "./OAuthMessage.css";

const youtubeAuthUri =
  "https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://api.futhelpers.com/youtubeOAuth&client_id=949088549295-bldssv39m75nlmce3ofjitm12dvpvleb.apps.googleusercontent.com&prompt=consent&access_type=offline&response_type=code&scope=https://www.googleapis.com/auth/youtube.readonly%20openid%20https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email&state=";

const getPopUpMessage = ({ isCancelled, discordId, cbState }) => {
  if (isCancelled) {
    return "Request cancelled, you can now close this tab";
  }
  console.log("cbState", cbState);
  if (cbState === "true") {
    return "Youtube and discord successfully linked. You may close this tab now";
  }

  if (cbState === "false") {
    return "Youtube and discord link failed. Please try again";
  }

  if (discordId) {
    return "You will be redirected to Youtube sign in to link your Discord server account with youtube account.";
  }
  return "Discord Id missing in the request";
};

export default function OAuthMessage() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const discordId = params.get("id");
  const cbState = params.get("state");
  const [isCancelled, setisCancelled] = useState(false);
  return (
    <>
      <span className="logo">
        <img className="logo-img" src={logo} alt="logo" />
      </span>
      <div className="message">
        <p className="text">
          {getPopUpMessage({ isCancelled, discordId, cbState })}
        </p>
      </div>
      {discordId &&
        !isCancelled &&
        (cbState === null || cbState === undefined) && (
          <div className="actions">
            <button
              className="btn-cancel btn"
              onClick={() => setisCancelled(true)}
            >
              CANCEL
            </button>
            <button
              className="btn-proceed btn"
              onClick={() => {
                window.open(`${youtubeAuthUri}${discordId}`, "_self");
              }}
            >
              LINK
            </button>
          </div>
        )}
      {cbState && (
        <div className="actions">
          <button
            className="btn-proceed btn"
            onClick={() => {
              window.close();
            }}
          >
            CONTINUE
          </button>
        </div>
      )}
    </>
  );
}
